import React from "react"
import { graphql, Link } from "gatsby"
import AdSense from "react-adsense"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Article } from "../style/article"
import { Button } from "../style/generic"
import { MDXRenderer } from "gatsby-plugin-mdx"

//@ts-ignore
export default function Template({ data }) {
  const { mdx } = data
  const { frontmatter } = mdx
  const featuredImg = frontmatter.featuredImage.childImageSharp.fluid

  const slugNodes = data.allSlugs.edges
  const activeSlug = slugNodes.find(
    node => node.node.frontmatter.slug === mdx.frontmatter.slug
  )

  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.description} />
      <Article>
        <header style={{ backgroundImage: `url(${featuredImg.src})` }}>
          <h1>{frontmatter.title}</h1>
        </header>
        <MDXRenderer>{mdx.body}</MDXRenderer>
        <div className="home">
          <Link to={"/"}>
            <Button>Home</Button>
          </Link>
        </div>
        {activeSlug.next ? (
          <div className="read-more">
            <h2>Want More?</h2>
            <span>Article</span>
            <h3>{activeSlug.next.frontmatter.title}</h3>
            <p>{activeSlug.next.excerpt}</p>
            <Link to={activeSlug.next.frontmatter.slug}>
              <Button>Read</Button>
            </Link>
          </div>
        ) : (
          <div className="read-more">
            <h2>Want More?</h2>
            <span>Collection</span>
            <h3>Exercise Database</h3>
            <p>
              Check out all bodyweight exercises in our exercise collection.
              Browse among different exercises and find the ones that work on
              the muscles you want to train.
            </p>
            <Link to={`/exercies/`}>
              <Button>Database</Button>
            </Link>
          </div>
        )}
      </Article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx: mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      timeToRead
      wordCount {
        words
      }
    }
    allSlugs: allMdx {
      edges {
        next {
          frontmatter {
            slug
            title
          }
          excerpt
        }
        previous {
          frontmatter {
            slug
            title
          }
          excerpt
        }
        node {
          frontmatter {
            slug
          }
        }
      }
    }
  }
`
